import React from 'react';
import './TitleAltNames.css';

export const TitleAltNames = ({ alt }) => {

    const joinedAlt = alt.join(' / ');

    return (
        <div className="h2-wrapper">
            <h2>{joinedAlt}</h2>
        </div>
    );
};
