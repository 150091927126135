import {ToggleRound} from "../../components/ToggleRound/ToggleRound";

import './Catalog.css'
import {useEffect, useState} from "react";
import {endPoint} from "../../config";

import {Link} from "react-router-dom";
import Cookies from "js-cookie";

export const Catalog = () => {
    const [data, setData] = useState([]);
    const [isError, setError] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [timer, setTimer] = useState();

    const [isBookmark, setIsBookmark] = useState(false);
    
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    useEffect(() => {
        clearTimeout(timer);
        setTimer(
            setTimeout(
                () => {
                    let url;

                    let init = {};

                    if (!!Cookies.get('token'))
                        init.headers = {
                            'Authorization': `Bearer ${Cookies.get('token')}`
                        }

                    if (searchText) {
                        url = `${endPoint}/v1/titles/search?is_bookmark=${isBookmark}&text=${searchText}`;
                    } else {
                        url = `${endPoint}/v1/titles/search?is_bookmark=${isBookmark}`;
                    }

                    fetch(url, init)
                        .then(response => response.json())
                        .then(response => {
                            setData(response.data);
                            clearTimeout(timer);
                        })
                        .catch(_ => setError(true));
                }, data.length ? 1000 : 0
            )
        );
    }, [isBookmark, searchText, isError]);

    return (
        <div className="catalog-wrapper">
            <h1>Каталог</h1>
            <div className="show-settings">
                <form>
                    <input type="search" onKeyPress={handleKeyPress} placeholder='Поиск...'
                           value={searchText}
                           onChange={(e) => setSearchText(e.target.value)}
                           className="search-field"/>
                </form>
                <div className="toggle-wrapper">
                    <ToggleRound isBookmark={isBookmark} setIsBookmark={setIsBookmark}/>
                    <p>Только мои закладки</p>
                </div>
            </div>
            <div className="titles">
                {
                    data.length ? data.map((title, index) => (
                            <div key={index} className="title-card-wrapper">
                                <Link to={'/manga/' + title.slug}>
                                    <div className="title-card">
                                        <img src={title.image_url} alt={title.name}/>
                                        <div className="title-card-text">
                                            <p className="title-name">{title.name}</p>
                                            <p className="chapter-count">{title.chapters[0]?.number} глава</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )) :
                        <div className='no-search-result'>
                            <p>Пока у нас нет такой манги :(</p>
                        </div>
                }
            </div>
        </div>
    )
}