import React, {useEffect, useState} from 'react';
import './ChapterListItem.css';
import {ChapterAllyInfo} from "../ChapterAllyInfo/ChapterAllyInfo";
import {Link, useParams} from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import {endPoint} from "../../config";

function convertDateFormat(isoDateString) {
    // Создаем объект Date из строки в формате ISO 8601
    const date = new Date(isoDateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    // Собираем дату в нужном формате
    return `${day}.${month}.${year}`;
}


export const ChapterListItem = (
    { is_preview, volume, chapter, preview_img, published,
        data_access, is_access, is_viewed, slug, id }
) => {

    const [data, setData] = useState(null);
    const [isError, setError] = useState(false);

    let ally_info = <ChapterAllyInfo is_preview={is_preview}
                                     published={published} data_access={data_access}
                                     convertDateFormat={convertDateFormat} />

    let is_view_mark = <div className='is_view_mark'></div>;
    if (is_viewed) {
        is_view_mark = '';
    }

    return (
        <Link to={ is_preview ? `/manga/${slug}/preview` : `/manga/${slug}/${id}`}
              id="chapterLink">
            <div className="chapters-item">
                <div className="content-part">
                    <p className="volume-num">{volume}</p>
                    <div className="chapter-img-wrapper">
                        <img src={preview_img}
                             width="103px" height="67px"></img>
                    </div>
                    <div className="chapter-text-data">
                        <p className="chapters-number">Глава {chapter}</p>
                        <p className="chapters-date">
                            {is_preview ? 'Анонс' : convertDateFormat(published)}
                        </p>
                    </div>
                </div>
                <div className="chapter-ally-info">
                    { is_access ? is_view_mark : ally_info }
                </div>
            </div>
        </Link>
    );
};
