import React from 'react'
import {Link, useParams} from "react-router-dom";

export const Cmp2 = () => {

    const {slug, chapter_id} = useParams();

    return (
        <>
            { chapter_id=='preview' ? <p>ЭТО СТРАНИЦА ПРЕВЬЮ</p> : <p>ЭТО СТРАНИЦА ГЛАВЫ</p> }
            <p>Наш slug: {slug}</p>
            <p>Наш chapter_id: {chapter_id}</p>
        </>
    )
}