import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";

import {TitleStartBlock} from "../../components/TitleStartBlock/TitleStartBlock";
import {TitleNamesBlock} from "../../components/TitleNamesBlock/TitleNamesBlock";
import {TitleChaptersBlock} from "../../components/TitleChaptersBlock/TitleChaptersBlock";

import {endPoint} from "../../config";
import './Title.css'


export const Title = () => {
    const {slug} = useParams();

    const [data, setData] = useState(null);
    const [isError, setError] = useState(false);

    useEffect(
        () => {
            fetch(`${endPoint}/v1/titles/${slug}`, {
                method: 'GET',
                credentials: 'include'
            })
            .then(response => response.json())
            .then(response => {
                setData(response.data);
            })
            .catch(_ => setError(true));
        }, [isError, slug]
    );

    if (!data) {
        return (
            <div style={{height: '1000px'}}>
                <p>DATA не загрузилась</p>
            </div>
        )
    }

    return (
        <>
            <div className="title-wrapper">
                <div className="main">
                    <TitleStartBlock views={data.views} bookmarks={data.bookmarks}
                                     image_url={data.image_url} name={data.name} vk_url={data.vk_url}
                                     boosty_url={data.boosty_url} next_chapter={data.next_chapter} slug={slug}/>
                </div>
                <div className="content">
                    <TitleNamesBlock text={data.description} name={data.name}
                                     alt_name={data.alternative_names}
                                     is_erotic={data.is_erotic}/>
                    <TitleChaptersBlock/>
                </div>
            </div>
            {/*{*/}
            {/*    data.map(*/}
            {/*        (title, index) => (*/}
            {/*            <>*/}
            {/*                <Link to={`/manga/${slug}/${title.id}`} key={index}>ПЛАШКА С ГЛАВОЙ</Link>*/}
            {/*            </>*/}
            {/*        )*/}
            {/*    )*/}
            {/*}*/}
        </>
    )
}