import React from 'react'
import './ButtonStartRead.css'
import Cookies from "js-cookie";
import {Link} from "react-router-dom";

export function ButtonStartRead ({next_chapter, slug}) {


    if (!!Cookies.get('token')) {
        return (
            <div className="read-button">
                <Link to={`/manga/${slug}/${next_chapter.id}`} className="button-p">
                    Начать читать
                </Link>
            </div>
        )
    }

    if (next_chapter.type === "first") {
        return (
            <div className="read-button">
                <Link to={`/manga/${slug}/${next_chapter.id}`} className="button-p">
                    Начать читать
                </Link>
            </div>
        )
    } else if (next_chapter.type === "normal") {
        return (
            <div className="read-button">
                <Link to={`/manga/${slug}/${next_chapter.id}`} className="button-p">
                    <p>Продолжить читать</p>
                    <p>Том {next_chapter.volume} Глава {next_chapter.number}</p>
                </Link>
            </div>
        )
    } else if (next_chapter.type === "last") {
        return (
            <div className="read-button">
                <p>
                    Всё прочитано
                </p>
            </div>
        )
    }
}