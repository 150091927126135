import React from 'react'
import './TitleNamesBlock.css'

import {TitleDescription} from "../TitleDescription/TitleDescription";
import {TitleAltNames} from "../TitleAltNames/TitleAltNames";

import age_rate from '../../img/age_rate.svg';
import {AuthorizedModal} from "../AuthorizedModal/AuthorizedModal";

export const TitleNamesBlock = ({text, name, alt_name, is_erotic}) => {
    return (
        <div className="title-names">
            <h1 className="name">{name}</h1>
            <div className="alt-names">
                <TitleAltNames alt={alt_name} />
                {is_erotic ?
                    <div className="age-rate-wrapper">
                        <div className="age-rate">
                            18+
                        </div>
                        {/*<img src={age_rate} alt="18+"></img>*/}
                    </div>
                    : <></>}
            </div>
            <div className="description-block">
                <TitleDescription text={text} />
            </div>
        </div>
    )
}