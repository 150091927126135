import React from 'react'
import './TitleStartBlock.css'
import views_icon from "../../img/views.svg";
import bookmarks_icon from "../../img/bookmarks.svg";
import vk_icon from "../../img/vk.svg";
import boosty_icon from "../../img/boosty.svg";
import {ButtonStartRead} from "../ButtonStartRead/ButtonStartRead";
import {ButtonAddBookmark} from "../ButtonAddBookmark/ButtonAddBookmark"

export const TitleStartBlock = ({slug, views, next_chapter, bookmarks, image_url, name, vk_url, boosty_url}) => {
    return (
        <div className="title-start">
            <div className="cover-wrapper">
                <img src={image_url} alt={name}/>
            </div>
            <div className="info">
                <div className="stats">
                    <div className="views">
                        <div className="views-values">
                            <img src={views_icon} alt=""/>
                            <p>{views}</p>
                        </div>
                    </div>
                    <div className="bookmarks">
                        <div className="bookmarks-values">
                            <img src={bookmarks_icon} alt=""/>
                            <p>{bookmarks}</p>
                        </div>
                    </div>
                </div>

                <div className="read-bookmarks-buttons">
                    <ButtonStartRead next_chapter={next_chapter} slug={slug}/>
                    <ButtonAddBookmark slug={slug}/>
                </div>

                <a href={vk_url} className="vk-read">
                    <img src={vk_icon} alt="Показать ещё"/>
                    Читать в ВК
                </a>
                <a href={boosty_url} className="boosty-read">
                    <img src={boosty_icon} alt="Показать ещё"/>
                    Читать на Бусти
                </a>
            </div>
        </div>
    )
}