import React from 'react'
import logo from '../../img//logo.svg'
import profile_icon from '../../img/profile.svg'
import './ChapterLayout.css'
import {Link, Outlet} from "react-router-dom";
import {ProfileButton} from "../ProfileButton/ProfileButton";

export function ChapterLayout() {
    return (
        <>
            <header className='main-header'>
                <nav className="main-nav">
                    <div className="left-half-header">
                        <Link to="/" className="logo">
                            <img src={logo} width='32px' alt=''/>
                        </Link>
                        <ul className="nav-list">
                            <li className="nav-items">
                                <Link to="/manga">Каталог</Link>
                            </li>
                        </ul>
                    </div>
                    <ProfileButton/>
                </nav>
            </header>
            <Outlet/>
        </>
    )
}
