import React from 'react'
import './App.css'

import {Route, Routes} from 'react-router-dom';
import {Main} from '../pages/Main/Main';
import {Bookmarks} from '../pages/Bookmarks/Bookmarks';
import {Catalog} from '../pages/Catalog/Catalog';
import {Title} from "../pages/Title/Title";
import {Cmp1} from "../components/TestComponents/Cmp1.js";
import {Cmp2} from "../components/TestComponents/Cmp2.js";

import {Layout} from "../components/Layout/Layout.js";
import {ChapterLayout} from "../components/ChapterLayout/ChapterLayout";


export default function App() {
    return (
        <Routes>
            <Route path={'/'} element={<Layout/>}>
                <Route index element={<Main/>}/>
                <Route path="manga" element={<Catalog/>}/>
                {/*<Route path="bookmarks" element={<Bookmarks/>}/>*/}
                <Route path="manga/:slug" element={<Title/>}/>
            </Route>
            <Route path={'/manga/:slug/:chapter_id'} element={<ChapterLayout/>}>
                <Route index element={<Cmp2/>}/>
            </Route>
        </Routes>
    )
}
