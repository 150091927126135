import React, { useEffect, useRef, useState } from 'react';
import './TitleDescription.css';
import show_more from '../../img/show_more.svg';

export const  TitleDescription = ({text}) => {

    const [isExpanded, setIsExpanded] = useState(false);
    const descriptionRef = useRef(null);

    useEffect(() => {
        const toggleTextVisibility = () => {
            if (descriptionRef.current) {
                if (isExpanded) {
                    descriptionRef.current.classList.remove('wrapping');
                } else {
                    descriptionRef.current.classList.add('wrapping');
                }
            }
        };

        toggleTextVisibility();
    }, [isExpanded]);

    const expandButton = isExpanded ?
        <div className="expand-info">
            <img src={show_more} style={{transform: 'rotate(180deg)'}}  alt="Скрыть"/>
            <p>Меньше</p>
        </div> :
        <div className="expand-info">
            <img src={show_more} alt="Показать ещё"/>
            <p>Больше</p>
        </div>

    return (
        <>
            <p ref={descriptionRef} className="description">
                {text}
            </p>
            <button className="expand-toggle" onClick={() => setIsExpanded(!isExpanded)}>
                {text.length > 100 ? expandButton : null}
            </button>
        </>
    );

    // return (
    //     <>
    //         <p className="description">
    //             {text}
    //         </p>
    //         <button id="toggleButton">Меньше</button>
    //     </>
    // );
}