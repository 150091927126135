import React, {useEffect, useState} from 'react';
import './AuthorizedModal.css';
import logout_icon from '../../img/logout.svg';
import anon_user_ava from "../../img/anon_user_ava.svg"
import Cookies from "js-cookie";
import {endPoint} from "../../config";

function logout(setIsProfileWindowOpen) {
    setIsProfileWindowOpen(false);
    document.cookie = 'token=; path=/; expires=-1; domain=.everlate.org; sameSite=Lax; secure: false';
}

function closeClickListener(element, setIsProfileWindowOpen, isProfileWindowOpen) {
    // Проверяем, произошел ли клик вне элемента .menu и модального окна
    if (!isProfileWindowOpen)
        return

    // Проверяем, не был ли клик сделан на кнопке .button-profile-icon или её потомках
    if (element.target.closest('.auth-modal-wrapper'))
        return;

    if (element.target.closest('.auth-modal-overlay'))
        return;

    if (element.target.closest('button.button-profile-icon'))
        return;

    setIsProfileWindowOpen(false);
}

export function AuthorizedModal({setIsProfileWindowOpen, isProfileWindowOpen}) {
    const [data, setData] = useState(null);
    const [isError, setError] = useState(false);

    console.log("Открыт профиль пользователя")

    useEffect(
        () => {
            let listener = (element) => closeClickListener(element, setIsProfileWindowOpen, isProfileWindowOpen);

            // Добавляем обработчик событий
            document.addEventListener('click', listener);

            // Функция очистки, которая удаляет обработчик событий
            return () => {
                document.removeEventListener('click', listener);
            };
        }, [isProfileWindowOpen, setIsProfileWindowOpen]
    );

    useEffect(
        () => {
            fetch(
                `${endPoint}/v1/users/profile?view_mode=compact`,
                {
                    method: 'GET',
                    credentials: "include",
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('token')}`
                    }
                }
            )
                .then(response => response.json())
                .then(
                    response => {
                        setData(response.data)
                    }
                )
                .catch(_ => setError(true));
        }, [isError]
    );

    if (!data)
        return;

    return (
        <div className="auth-modal-overlay">
            <div className="auth-modal-wrapper">
                <div className="auth-modal">
                    <div className="auth-modal-profile-data">
                        <div
                            className={data.is_sub ? 'auth-modal-avatar' : "non-auth-modal-avatar"}>
                            <img src={anon_user_ava}
                                 className='auth-user-avatar'
                                 alt="Аватар"/>
                        </div>
                        <div className="auth-modal-name">{data.name}</div>
                    </div>

                    {data.is_sub ? <></> :
                    <div className="subscribe-button-wrapper">
                        <button className="subscribe-button">Оформить подписку</button>
                        <p className="subscribe-button-text">Некоторые главы на нашем сайте, доступны только донам.</p>
                    </div>}

                    <ul className="list-user-modal">
                        <li>Профиль</li>
                        <li>Обратная связь</li>
                        <li>
                            <button className="logout-item" onClick={() => logout(setIsProfileWindowOpen)}>
                                <p>Выйти</p>
                                <img className="logout-icon" src={logout_icon} alt="Выход"/>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
