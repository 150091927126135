import React, {useEffect, useState} from 'react'
import './TitleChaptersBlock.css'

import {useParams} from "react-router-dom";
import {endPoint} from "../../config";
import {ChapterListItem} from "../ChapterListItem/ChapterListItem";
import {ContentCard} from "../ContentCard/ContentCard";
import Cookies from "js-cookie";

export const TitleChaptersBlock = () => {
    const {slug} = useParams();

    const [data, setData] = useState(null);
    const [isError, setError] = useState(false);

    useEffect(
        () => {
            let init = {
                method: 'GET',
                credentials: "include"
            };

            if (!!Cookies.get('token'))
                init.headers = {
                    'Authorization': `Bearer ${Cookies.get('token')}`
                }
            
            fetch(`${endPoint}/v1/titles/${slug}/chapters`, init)
                .then(response => response.json())
                .then(response => {
                    setData(response.data);
                })
                .catch(_ => setError(true));
        }, [isError, slug]
    );

    if (!data) {
        return (
            <p>Пусто всё</p>
        )
    }

    let previewCard;
    let chaptersArray;

    let chapterCards = Array.from(
        {length: data.chapters.length}, (_, index) => (
            <ChapterListItem key={index + 1}  volume={data.chapters[index].volume}
                             chapter={data.chapters[index].number}
                             preview_img={data.chapters[index].preview}
                             published={data.chapters[index].published_at}
                             data_access={data.chapters[index].public_access_at}
                             is_viewed={data.chapters[index].is_view}
                             is_access={data.chapters[index].is_access}
                             slug={slug} id={data.chapters[index].id} />
        )
    );

    if (data.preview) {
        previewCard = <ChapterListItem key={0} volume={data.preview.volume}
                                       chapter={data.preview.number}
                                       preview_img={data.preview.preview}
                                       published={data.preview.published_at}
                                       is_preview={true}
                                       slug={slug} />
        chaptersArray = [previewCard, ...chapterCards.reverse()]
    } else {
        chaptersArray = [...chapterCards]
    }

    return (
        <div className="title-chapters">
            <div className="title-chapters-head">
                <p className="chapters-count">{data.chapters.length + (data.preview ? 1 : 0)} Глав</p>
                <div>
                    <p>Показать с конца</p>
                </div>
            </div>
            <div className="title-chapters-list">
                {chaptersArray}
            </div>
        </div>
    )
}