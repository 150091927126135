import Cookies from "js-cookie";
import React, {useState} from 'react'
import logo from '../../img//logo.svg'
import profile_icon from '../../img/profile.svg'
import vk_icon from '../../img/vk.svg'
import tg_icon from '../../img/tg.svg'
import './Header.css'
import './Footer.css'
import {Link, Outlet} from "react-router-dom";
import {ProfileButton} from "../ProfileButton/ProfileButton";


export function Layout() {
    return (
        <>
            <header className='main-header'>
                <nav className="main-nav">
                    <div className="left-half-header">
                        <Link to="/" className="logo">
                            <img src={logo} width='32px' alt=''/>
                        </Link>
                        <ul className="nav-list">
                            <li className="nav-items">
                                <Link to="/manga">Каталог</Link>
                            </li>
                            {/*<li className="nav-items">*/}
                            {/*    <Link to="/bookmarks">Закладки</Link>*/}
                            {/*</li>*/}
                        </ul>
                    </div>
                    <ProfileButton/>
                </nav>
            </header>
            <Outlet/>
            <footer className="main-footer">
                <div className="footer-contacts">
                    <div className="footer-contacts-left footer-part">
                        <p className="info-fotter h-conctact-block">
                            <a href="#">ИНФО</a>
                        </p>
                        <p className="info-fotter">
                            <a href="#">Авторское право</a>
                        </p>
                        <p className="info-fotter">
                            <a href="#">Пользовательское соглашение</a>
                        </p>
                    </div>
                    <div className="footer-contacts-center footer-part">
                        <p className="contact-us h-conctact-block">Связаться с нами</p>
                        <div className="footer-icons">
                            <a href="#" className="footer-contacts-item"><img src={vk_icon} alt=""/></a>
                            <a href="#" className="footer-contacts-item"><img src={tg_icon} alt=""/></a>
                        </div>
                    </div>
                    <div className="footer-contacts-right footer-part">
                        <p className="email h-conctact-block">Почта для связи</p>
                        <p className="email" >
                            <a href='mailto:avalon@translate.ru'>avalon@translate.ru</a>
                        </p>
                    </div>
                </div>
                <div className="rights-reserved">
                    <p> 2024 Avalon. Все права защищены. </p>
                </div>
            </footer>
        </>
    )
}
