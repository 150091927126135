import React from 'react'
import {LastUpdate} from '../../components/LastUpdate/LastUpdate.js'
import {Carousel} from '../../components/Slider/Slider.js'
import {TestModalAuth} from "../../components/TestModalAuth/TestModalAuth";
import Skeleton from 'react-loading-skeleton';


export const Main = () => {
    return (
        <>
            <Carousel />
            {/*<TestModalAuth />*/}
            <LastUpdate />
            </>
    )
}
