import React from 'react';
import './NotAuthorizedModal.css';
import {TelegramButton} from "../TelegramButton/TelegramButton";
import close_icon from '../../img/close.svg';

export function NotAuthorizedModal({ setIsModalOpen}) {
    const handleCloseModal = (e) => {
        if (e.target.className.includes('modal-overlay')) {
            setIsModalOpen(false);
        }
    };

    return (
        <div className="modal-overlay" onClick={handleCloseModal}>
            <div className="modal-content-wrapper">
                <button className="close-button" onClick={(e) => {
                    e.stopPropagation(); // Предотвращаем всплытие события
                    setIsModalOpen(false);
                }}>
                    <img src={close_icon}/>
                </button>
                <div className="modal-content">
                    <p className="auth-h">Авторизация</p>
                    <TelegramButton setIsModalOpen={setIsModalOpen}/>
                    <span className="policy-text">Продолжая, вы принимаете <span className="policy-highlight">пользовательское соглашение</span> и политику конфиденциальности</span>
                </div>
            </div>
        </div>
    );
}
