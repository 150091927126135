import React, {useEffect, useState} from 'react'
import './ButtonAddBookmark.css'
import {endPoint} from "../../config";

export function ButtonAddBookmark ({slug}) {

    const [isBookmark, setIsBookmark] = useState(null);
    const [isError, setError] = useState(false);

    useEffect(
        () => {
            fetch(`${endPoint}/v1/titles/${slug}/bookmark`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(response => response.json())
                .then(response => {
                    setIsBookmark(response.data.is_bookmark);
                })
                .catch(_ => setError(true));
        }, [isError, slug]
    );

    console.log(isBookmark)

    if (!isBookmark) {
        return (
            <div className="bookmark-button in-bookmark">
                <p className="in-bookmark-text">Добавлено в закладки</p>
            </div>
        )
    }

    return (
        <div className="bookmark-button">
            <button className="button-p" onClick={() => {
            }}>Добавить в закладки
            </button>
            {/*<a href="https://example.com" className="button-p">Добавить в закладки</a>*/}
        </div>
    )
}