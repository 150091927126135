import {Splide, SplideSlide} from '@splidejs/react-splide';
import {endPoint} from "../../config";
import {useEffect, useState} from 'react';
import '@splidejs/react-splide/css';

import './Slider.css'
import {Link} from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const OPTIONS = {
    type: 'loop',
    perPage: 1,
    rewind: true,
    autoWidth: true,
    gap: '8px',
    focus: 'center',
    arrows: false,
    clones: 12,
    cover: true
}

export function Carousel() {
    const [data, setData] = useState([]);
    const [isError, setError] = useState(false);

    useEffect(
        () => {
            fetch(`${endPoint}/v1/titles/recommendations`, {
                method: 'GET',
                credentials: "include"
            })
            .then(response => response.json())
            .then(response => {
                setData(response.data);
            })
            .catch(_ => setError(true));
        }, [isError]
    );

    let sliders;

    if (!data.length) {
        sliders = (
            <SplideSlide>
                    <Skeleton className="skeleton-slider-card"
                              baseColor="#292929"
                              highlightColor="#404040"
                              duration={0.8}
                    />
            </SplideSlide>
        )
    } else {
        sliders = data.map(
            (title, index) => (
                <SplideSlide key={index}>
                    <Link to={`/manga/${title.slug}`} className='chapter-link'>
                        <div className="slider-card">
                            <img src={title.image_url} alt={title.name}/>
                            <div className="slider-card-text">
                                <p>{title.name}</p>
                            </div>
                        </div>
                    </Link>
                </SplideSlide>
            )
        )
    }

    return (
        <Splide options={OPTIONS}>
            {sliders}
        </Splide>
    );
}
