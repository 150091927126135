import React from 'react';
import './ChapterAllyInfo.css';

import bell from '../../img/bell.svg';
import lock from '../../img/lock.svg';

export const ChapterAllyInfo = (
    { is_preview, published, data_access, convertDateFormat }
) => {

    return (
        <>
            {is_preview ?
                <div className='preview-info'>
                    <div className="anounce-text">
                        <p>Появится здесь</p>
                        <p>{convertDateFormat(published)}</p>
                    </div>
                    <div className="bell-icon-wrapper">
                        <img src={bell} alt="Анонс главы манги"/>
                    </div>
                </div> :
                <div className='preview-info'>
                    <div className="anounce-text">
                        <p>Доступно с подпиской</p>
                        <p>Откроется {convertDateFormat(data_access)}</p>
                    </div>
                    <div className="bell-icon-wrapper">
                        <img src={lock} width="20px" alt="Глава манги доступна подписчикам"/>
                    </div>
                </div>}
        </>
    );
};
