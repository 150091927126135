import './ToggleRound.css';

export function ToggleRound({isBookmark, setIsBookmark}) {

    const handleChange = (event) => {
        setIsBookmark(!isBookmark);
        if (event.target.checked) {
            console.log('123');
        } else {
            console.log('456');
        }
    };

    return (
        <div className="toggle-wrapper">
            <label className="switch">
                <input type="checkbox" className="check-box-status" checked={isBookmark} onChange={handleChange}/>
                <span className="slider round"></span>
            </label>
        </div>
    );
}
