import '../LastUpdate/LastUpdate.css'
import {useEffect, useState} from "react";
import {endPoint} from "../../config";
import {Link} from "react-router-dom";
import Cookies from "js-cookie";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

function getWordInDeclension(number, one, two, five) {
    let value = number % 100;

    if (11 <= value && value <= 20) {
        return `${value} ${five} назад`;
    }

    value %= 10

    if (value === 1) {
        return `${value} ${one} назад`;
    }

    if (2 <= value && value <= 4) {
        return `${value} ${two} назад`;
    }

    return `${value} ${five} назад`;
}

function hoursAgo(isoDateString) {
    let timeDiff = new Date().getTime() - new Date(isoDateString).getTime();

    let hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
    let MinutesDiff = Math.floor(timeDiff / (1000 * 60));

    switch (true) {
        case (MinutesDiff < 60):
            return getWordInDeclension(MinutesDiff, 'минуту', 'минуты', 'минут');
        case (1 <= hoursDiff && hoursDiff < 24):
            return getWordInDeclension(hoursDiff, 'час', 'часа', 'часов');
        case (24 <= hoursDiff && hoursDiff <= 24 * 7):
            return getWordInDeclension(Math.floor(hoursDiff / 24), 'день', 'дня', 'дней');
        case (24 * 7 < hoursDiff && hoursDiff < 366 * 24):
            const date = new Date(isoDateString).getDate()
            const monthName = new Date(isoDateString).toLocaleString('default', {month: 'short'});
            return `${date} ${monthName}`;
        default:
            const dateFull = new Date(isoDateString).getDate()
            const monthNameFull = new Date(isoDateString).toLocaleString('default', {month: 'short'});
            const year = new Date(isoDateString).getFullYear()
            return `${dateFull} ${monthNameFull} ${year}`;
    }
}

export function ContentCard({page, titleCount, isBookmark}) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let init = {
                    method: 'GET',
                    credentials: "include"
                };

                if (!!Cookies.get('token')) {
                    init.headers = {
                        'Authorization': `Bearer ${Cookies.get('token')}`
                    }
                }

                const response = await fetch(`${endPoint}/v1/titles/last-updates?page=${page}&is_bookmark=${isBookmark}`, init);
                const result = await response.json();

                setData(result.data);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(true);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [isBookmark, page]);

    // if (!data.length) {
    //     return (
    //         <>
    //             {
    //                 Array.from({length: titleCount}).map(
    //                     (_, index) => (
    //                         <div className="content-card-wrap" key={index}>
    //                             <div className="content-card ghost-content-card"></div>
    //                         </div>
    //                     )
    //                 )
    //             }
    //         </>
    //     );
    // }



    if (isLoading) {
        return (
                <>
                    {Array(titleCount).fill(null).map((_, index) => (
                        <div className="skeleton-card-wrap" key={index}>
                            <Skeleton className="skeleton-body-card"
                                      baseColor="#292929"
                                      highlightColor="#404040"
                                      duration={0.8}
                                      style={{ borderRadius: '15px'}}
                            />
                        </div>
                    ))}
                </>
        );
    }

    console.log('content card')

    if (isError) {
        return <div>Error loading data</div>;
    }

    return (
        <>
            {
                data.map(
                    (title, index) => (
                        <div className="content-card-wrap" key={index}>
                            <div className="content-card">
                                <div className="title-cover-wrapper">
                                    <Link to={`/manga/${title.slug}`}>
                                        <img className="content-card-img" src={title.image_url} alt=""/>
                                    </Link>
                                </div>
                                <div className="text-content-card">
                                    <div>
                                        <Link to={`/manga/${title.slug}`}>
                                            <h3 className="text-content-card-title">{title.name}</h3>
                                        </Link>
                                    </div>
                                    <div className="text-content-card-chapters&date">
                                        <ul className="text-content-card-list">
                                            {
                                                title.chapters.map(
                                                    (chapter, index_child) => (
                                                        <li className="text-content-card-list-item" key={index_child}>
                                                                <div className="chapter-num">
                                                                    <Link to={`/manga/${title.slug}/${chapter.id}`} className="chapter-link">
                                                                        <p>Том {chapter.volume} Глава {chapter.number}</p>
                                                                    </Link>
                                                                </div>
                                                                <div className="chapter-data">
                                                                    <Link to={`/manga/${title.slug}/${chapter.id}`} className="chapter-link">
                                                                        <p>{hoursAgo(chapter.published_at)}</p>
                                                                    </Link>
                                                                </div>
                                                        </li>
                                                    )
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                )
            }
        </>
    );
}
