import React, {useState} from 'react';
import './ProfileButton.css';
import {NotAuthorizedModal} from "../NotAuthorizedModal/NotAuthorizedModal.js";
import {AuthorizedModal} from "../AuthorizedModal/AuthorizedModal.js";
import profile_icon from "../../img/profile.svg";
import Cookies from "js-cookie";

export function ProfileButton() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    let isAuthenticated = !!Cookies.get('token');

    console.log('профиль бутон', isAuthenticated);
    return (
        <div className="nav-profile">
            <button className="button-profile-icon" onClick={() => setIsModalOpen(!isModalOpen)}>
                <img src={profile_icon} width="38px" alt=""/>
            </button>
            {isModalOpen && isAuthenticated ? (
                <AuthorizedModal setIsProfileWindowOpen={setIsModalOpen} isProfileWindowOpen={isModalOpen} isAuthenticated={isAuthenticated}/>
            ) : (
                isModalOpen && !isAuthenticated ?
                    (<NotAuthorizedModal setIsModalOpen={setIsModalOpen}/>) : null
            )}
        </div>
    )
};
