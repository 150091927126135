import Cookies from 'js-cookie';
import React, {useEffect, useState} from "react";
import './LastUpdate.css'
import {ToggleRound} from "../ToggleRound/ToggleRound";

import {ContentCard} from "../ContentCard/ContentCard";
import {endPoint} from "../../config";
import showMore from "../../img/show_more.svg";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

export function LastUpdate() {
    const [page, setPage] = useState(1);
    const [titleCount, setTitleCount] = useState(null);
    const [isError, setError] = useState(false);
    const [isBookmark, setIsBookmark] = useState(false);

    useEffect(
        () => {
            let init = {
                method: 'GET',
                credentials: "include"
            };

            if (!!Cookies.get('token'))
                init.headers = {
                    'Authorization': `Bearer ${Cookies.get('token')}`
                }

            fetch(`${endPoint}/v1/titles/last-updates/title-count?is_bookmark=${isBookmark}`, init)
                .then(response => response.json())
                .then(
                    response => {
                        setTitleCount(response.data)
                    }
                )
                .catch(_ => setError(true));
        }, [isBookmark, isError]
    );

    let contentCards = Array.from(
        {length: page}, (_, index) => (
            <ContentCard key={index} page={index + 1}
                         titleCount={Math.min((titleCount ? titleCount : 6) - index * 6, 6)}
                         isBookmark={isBookmark}/>
        )
    );

    let button;

    if (titleCount && (page*6 <= titleCount)) {
        button = <button className="btn-load-more" onClick={() => setPage(page + 1)}>
            Показать ещё
            <img src={showMore} alt="Показать ещё"/>
        </button>;
    } else {
        if (titleCount !== null) {
            button = <></>
        } else {
            button =
                <>
                    <br/>
                    <div className="skeleton-ghost-button-wrapper">
                        <Skeleton className="skeleton-ghost-button"
                                  baseColor="#292929"
                                  highlightColor="#404040"
                                  duration={0.8}
                                  style={{borderRadius: '35px'}}
                        />
                    </div>
                </>
        }
    }

    return (
        <div className="last-update-container">
            <div className="last-upd-head">
                <h2 className="text-last-update">Последние обновления</h2>
                <div className="toggle-last-update">
                    <ToggleRound isBookmark={isBookmark} setIsBookmark={setIsBookmark}/>
                    <p>Только мои закладки</p>
                </div>
            </div>
            <div className="last-update-content">
                <>
                    {contentCards}
                    {button}
                </>
            </div>
        </div>
    );
}