import React, {useEffect, useState} from 'react';
import {endPoint} from '../../config';
import './TelegramButton.css';
import tg_auth_icon from '../../img/tg_auth.svg';

export function TelegramButton({setIsModalOpen}) {
    const [user, setUser] = useState(null);
    const [isError, setError] = useState(false);

    useEffect(() => {
        if (!user)
            return;

        fetch(
            `${endPoint}/v1/users/auth/telegram`,
            {
                method: 'POST',
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(user)
            }
        ).then(
            response => {
                if (response.status !== 201) {
                    setError(true);
                    return;
                }

                setIsModalOpen(false);
            }
        ).catch(_ => setError(true));
        console.log(user);
        console.log(isError);
    }, [user, isError]);

    return (
        <>
            <button className="tg-auth-button" onClick={() => {
                return window.Telegram.Login.auth({bot_id: '7380830896', request_access: true}, setUser);
            }}>
                <img src={tg_auth_icon}/>
                Войти через Telegram
            </button>
        </>
    );
}
